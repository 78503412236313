<template>
    <div class="home">
        <el-carousel :height="Height + 'px'" :class="{Mobile:isMobile}">
            <el-carousel-item>
                <img src="@/assets/img/home/img0.png" class="bar_img">
            </el-carousel-item>
        </el-carousel>
        <div class="content">
            <div class="row1 flex_r">
                <div class="piece flex_c flex_as flex_jc">
                    <div class="p1">3D时光机</div>
                    <div class="p">新一代裸眼3D影音设备，AI科技让三维世界尽现眼前</div>
                    <div class="bnt">了解更多<span class="icof">&#xe601;</span></div>
                </div>
                <img src="@/assets/img/home/img5.png" class="piece">
            </div>
            <div class="row2 flex_c flex_as flex_jc">
                <div class="p1">AI-3D算法，画随眼动</div>
                <div class="p">应用 AI-3D 人眼追踪技术，精准追踪双眼并计算空间深度，调整3D画面最佳状态，带来丝滑的3D体验。</div>
            </div>
            <div class="row3">
                <div class="con_l flex_c flex_jc">
                    <div class="p1">光场动态调控，裸眼3D新体验</div>
                    <div class="p">运用了业界领先的光场动态调控技术集成了经过特殊设计的纳米LC层,实现令人沉浸其中的裸眼3D显示效果。</div>
                </div>
                <div class="con_r flex_c flex_jc">
                    <img src="@/assets/img/home/img6.png">
                </div>
            </div>
            <div class="row4 flex_r">
                <div class="piece flex_c flex_ac flex_jc">
                    <div class="p1">3D 精彩无限，2D无损展现</div>
                    <img src="@/assets/img/home/img7.png">
                    <div class="p">独特纳米 LC 层可实现震撼 3D 显示，也能无损切换 2D 平面 清晰的 2K 画质，远超市面95%以上3D设备</div>
                    <div class="bnt">了解更多<span class="icof">&#xe601;</span></div>
                </div>
                <div class="col_l">
                    <div class="piece2 flex_c flex_as flex_jc">
                        <div class="p1">流线之蜗，视听杰作</div>
                        <div class="imgs">
                            <img src="@/assets/img/home/sound1.png">
                            <img src="@/assets/img/home/sound2.png">
                        </div>
                        <div class="bnt">了解更多<span class="icof">&#xe601;</span></div>
                        <img src="@/assets/img/home/img1.png" class="p_img">
                    </div>
                    <div class="piece2 flex_c flex_as flex_jc">
                        <div class="p1">流线之蜗，视听杰作</div>
                        <div class="p">替代高昂的VR穿戴设备，一键传输空间视频，无需穿戴轻松展示空间（3D）画面</div>
                        <div class="bnt">了解更多<span class="icof">&#xe601;</span></div>
                        <img src="@/assets/img/home/img2.png" class="p_img">
                    </div>
                </div>
            </div>
            <div class="row5 flex_r flex_wrap">
                <div class="title">
                    <div class="t1">功能特性</div>
                    <div class="t2">Functional features</div>
                </div>
                <div class="piece">
                    <div class="p1">魔法3D</div>
                    <div class="p">照片图片-键生成 3D 立体画面，赋予图像新生命</div>
                    <img src="@/assets/img/home/img10.png">
                </div>
                <div class="piece piece2">
                    <div class="p1">共享云相册</div>
                    <div class="p">为您提供安全可靠的存储、方便易用的共享、多平台互通等相册功能。帮助你记录和分享生活中的每一个重要瞬间</div>
                </div>
            </div>
            <div class="row6 flex_r flex_ac flex_jb">
                <div class="col_l">
                    <div class="p1">联系我们</div>
                    <div class="p">如需联系我们业务合作，请您留下邮箱，我们将会在7个工作日内联系您</div>
                </div>
                <div class="col_r">
                    <div class="p">lucky smart 期待您的到来</div>
                    <div class="inpt flex_r flex_ac">
                        <input placeholder="请填写联系邮箱" />
                        <div class="inp_btn">订阅</div>
                    </div>
                </div>
            </div>
        </div>
        <foot-bar />
    </div>
</template>
<script>
let that;
import footBar from "@/components/footbar.vue"
export default {
    name: "home",
    components: {
        footBar,
    },
    data() {
        return {
            Height: 812,
            isMobile: false,
        };
    },
    created() {
        that = this
        this.autoHeight()
        window.addEventListener('resize', () => {
            that.autoHeight()
        });
    },
    mounted() {},
    methods: {
        autoHeight() {
            this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 768;
            if(this.isMobile) this.Height = 670
            else this.Height = document.documentElement.clientHeight;
        }
    },
};
</script>
<style lang="scss" scoped>
.bar_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.p1 {
    font-size: 34px;
    font-weight: 600;
}

.p {
    font-size: 16px;
    line-height: 24px;
}

.bnt {
    margin-top: 36px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    height: 38px;
    line-height: 38px;
    padding: 0 14px;
    border: 1px solid #000;
    border-radius: 4px;

    .icof {
        margin-left: 6px;
    }
}

.row1 {
    height: 528px;
    .piece{
        width: 50%;
        padding-left: 72px;
    }
    .p{
        margin-top: 18px;
    }
    img{
        height: 100%;
    }
}

.row2{
    height: 558px;
    background-image: url("@/assets/img/home/img9.png");
    background-size: 100% 100%;
    padding-left: 72px;
    .p{
        width: 432px;
        margin-top: 18px;
    }
}

.row3 {
    width: 100%;
    height: 558px;
    position: relative;

    .con_l {
        height: 100%;
        padding-left: 36px;
        position: absolute;
        left: 0;
        top: 0;
        color: #000;

        .p1 {
            margin-bottom: 38px;
        }

        .p {
            width: 346px;
            font-size: 20px;
            line-height: 30px;
        }
    }

    .con_r {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        img {
            height: 86%;
        }
    }
}

.row4 {
    height: 616px;
    background: #FAFAFA;

    .piece {
        width: 50%;
        height: 100%;
        text-align: center;

        img {
            width: 100%;
            height: 236px;
            margin: 36px 0;
        }
    }

    .p {
        width: 430px;
    }

    .col_l{
        width: 50%;
    }

    .piece2 {
        width: 100%;
        height: 308px;
        padding: 0 39px;
        position: relative;

        .p_img {
            position: absolute;
            right: 0; bottom: 0;
            width: 235px;
            height: 173px;
        }

        .imgs {
            margin-top: 36px;

            img {
                width: 70px;
                height: 70px;
                margin-right: 36px;
                border-radius: 4px;
            }
        }

        .p {
            width: 292px;
            margin-top: 30px;
        }
    }
}

.row5 {
    .title {
        width: 100%;
        font-size: 16px;
        color: #222;
        text-align: center;
        padding: 140px 0 68px;

        .t1 {
            font-size: 48px;
            font-weight: bold;
        }
    }

    .piece {
        width: 50%;
        height: 616px;
        background-color: #DFE4ED;
        padding: 62px 50px;
        text-align: center;

        .p1 {
            margin-bottom: 20px;
        }

        .p {
            margin-bottom: 60px;
        }

        img {
            width: 346px;
        }
    }

    .piece2 {
        background-image: url("@/assets/img/home/img8.png");
        background-size: 100% 100%;
    }
}

.row6 {
    padding: 0 52px;
    height: 360px;
    .p{
        margin-top: 22px;
    }
    .inpt{
        width: 430px;
        height: 46px;
        line-height: 46px;
        border: 1px solid #000;
        margin-top: 22px;
        border-radius: 4px;
        overflow: hidden;
        input{
            width: calc(100% - 113px);
            padding: 0 15px;
            height: 100%;
        }
        .inp_btn{
            width: 113px;
            height: 100%;
            text-align: center;
            color: #fff;
            background-color: #333;
        }
    }
}

.Mobile{
    width: 1200px;
}
</style>